export class ContextModel {
  token: string = "";
  tokenExpiryTime: string = "";
  isFatchinToken: boolean = false;
  userId: number = 0;
  userKey: string = "";
  // userRole: any = [];
  userRole: any = "";
  loginClick: boolean = true;
  firstName: string = "";
  lastName: string = "";
  fullName: string = "";
  email: string = "";
  countryID: number = 91;
  consumerId: number = 0;
  consumerName: string = "";
  buyerId: number = 0;
  buyerIdForDefaultDeliveryAddress: number = 0;
  branchId: number = 0;
  branchGstNumber: string = "";
  selectedFirmId: number = 0;
  selectedBranchId: number = 0;
  firmId: number = 0;
  firmName: string = "";
  firmPanNumber: string = "";
  financialYear: string = "";
  currentFinancialYear: string = "";
  previousFinancialYear: string = "";
  financialYearStartDate: string = "";
  financialYearEndDate: string = "";
  isTrialActive: number = 0;
  trailEndDays: number = 0;
  remainingDays: number = 0;
  trialStartDate: string = "";
  subscriptionId: number = 0;
  planStartDate: string = "";
  planEndDate: string = "";
  branchName: string = "";
  stateId: number = 0;
  stateCode: string = "";
  isStateUT: boolean = false;
  maxAllowedAdmins: number = 0;
  maxAllowedUsers: number = 0;
  maxAllowedBranches: number = 0;
  maxAllowedFirms: number = 0;
  enablePos: boolean = false;
  enablePosForFirm: boolean = false;
  defaultBarcodeType: string = "barcode";
  defaultBarcodeSubType: string = "CODE128";
  defaultBarcodePrintPaperType: string = "A4";

  isAlert: boolean = false;
  enableCompositionTax: boolean = false;
  enableBatchwiseTax: boolean = false;
  enableFreeQuantity: boolean = false;
  alertMessage: string = "";
  alertType: string = "success";

  isLoadding: boolean = false;
  isMenuVisible: boolean = false;

  isThemeDark: boolean = false;
  isUnauthorized: boolean = false;
  AndroidAppVersion: string = "";
  AndroidBuildNumber: number = 0;
}
